import axios from "axios";


export const API_URL = "http://65.108.90.115:7770/iiko-integration/api/v1";



export const getTableOrder = async (organization_id: any, table_id: any) => {
    const http = axios.create({
        baseURL: API_URL,
        headers: {
            Accept: "application/json",
        },
        withCredentials: false,
    });

    http.interceptors.response.use(
        function (response) {
            console.log(response);
            return response;
        },
        function (error) {
            console.log(error);
            if (error.response && error.response.status === 401) {
                return { "authorized": false };
            }
            return { "authorized": false };
        }
    );

    const { data } = await http.get<any>(`${API_URL}/get_active_table_order?organization_id=${organization_id}&table_id=${table_id}`);
    return data; //result содержит необходимые данные
};

export const makePayment = async(request:any) => {
    const{data}= await axios.post<any>( `${API_URL}/make_payment`,request);
    return data;
};