import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Divider, Flex, Row, Spin, Typography } from 'antd';
import icon from "../../assets/tipsicon.svg";
import './PaymentForm.css';
import ErrorModal from "../PopupModals/ErrorModal";
import { useLocation } from "react-router-dom";
import { getTableOrder, makePayment } from "../../servises";



const data = [
    { percentage: '5', value: '5.23₼ ' },
    { percentage: '10', value: '10.46₼ ' },
    { percentage: '15', value: '15.69₼ ' },
    { percentage: '20', value: '20.93₼' },
];
const { Title, Text } = Typography;
interface OrderItem {
    type: string;
    productName: string;
    productCount: number;
    productPrice: number;
    resultSum: number;
    status: string;
}

interface TableOrderResponse {
    success: boolean;
    message: string;
    result: {
        organizationId: string;
        tableId: string[];
        orderId: string;
        restaurantName: string;
        waiterName: string;
        totalAmount: number;
        serviceFee: number | null;
        organizationName: string
        orderItems: OrderItem[];
        tipAmount: number;
        iikoOrganizationId: string;
        iikoTableId: string;
        iikoOrderId: string;
        waiterId: string;
        waiterPhoneNumber: string;
    };
    menuUrl: string;
}
const PaymentForm = () => {

    const [total, setTotal] = useState(0);
    const [serviceCharge, setServiceCharge] = useState(0);
    const [tipPercentage, setTipPercentage] = useState(0);
    const [visible, setVisible] = useState(false);
    const [orderData, setOrderData] = useState<any[]>([]);
    const [activeIndex, setActiveIndex] = useState(null);
    const [rest, setRest] = useState('')
    const [name, setName] = useState('')
    const [className, setClassName] = useState(false)
    const [organizationId, setOrganizationId] = useState('')
    const [tableId, setTableId] = useState('')
    const [orderId, setOrderId] = useState('')
    const [waiterId, setWaiterId] = useState('')
    const [waiterPhoneNumber, setWaiterPhoneNumber] = useState('')
    const [tipAmount, setTipAmount] = useState(0)
    const [grandTotalAmount, setGrandTotalAmount] = useState(0)
    const [orderItems, setOrderItems] = useState()
    const [url, setUrl] = useState('')
    const [loading, setLoading] = useState(false)
    const [loadingTable, setLoadingTable] = useState(false)
    const [spinnerLoading, setSpinnerLoading] = useState(true)
    const [dataSuccess, setDataSuccess] = useState(false)
    const loc: any = useLocation()
    const checkData = [
        { quantity: '1x', name: 'Smoked Salmon Bruschetta', price: '15.00 ₼' },
        { quantity: '2x', name: 'Chicken Tenders', price: '8.00 ₼' },
        { quantity: '1x', name: 'Toyuq kababı', price: '10.00 ₼' },
        { quantity: '2x', name: 'Quzu kababı', price: '12.30 ₼' },
        { quantity: '2x', name: 'Caesar salad with chicken', price: '20.24 ₼' },
        { quantity: '1x', name: 'Mini Beef Burgers', price: '20.00 ₼' },
    ];

    const location = useLocation()

    const fetchData = async (organization_id: any, table_id: any) => {
        setLoadingTable(true)
        const data = await getTableOrder(organization_id, table_id);
        // const data = {
        //     result: {
        //         orderItems: [
        //             {
        //                 productName: '',
        //                 resultSum: 0,
        //                 productCount: 0
        //             }
        //         ],
        //         totalAmount: 0,
        //         serviceFee: 0,
        //         tipAmount: 0,
        //         iikoOrganizationId: '',
        //         iikoTableId: '',
        //         iikoOrderId: '',
        //         waiterId: '',
        //         waiterName: '',
        //         waiterPhoneNumber: '',
        //         organizationName: '',
        //         resultSum: 0,
        //     },

        //     success: false,
        //     message: '',
        //     menuUrl: ''
        //     // menuUrl: 'http://www.google.com',

        // }

        const result = data.result
        console.log('fetch data success', data.success);
        setDataSuccess(data.success);
        if (data.success) {

            let temp_data: Array<any> = [];
            if (Array.isArray(result.orderItems)) {
                setOrderItems(result.orderItems)
                setGrandTotalAmount(result.totalAmount)
                setTotal(result.totalAmount)
                for (let row of result.orderItems) {
                    temp_data.push({
                        quantity: row.productCount,
                        name: row.productName,
                        price: row.resultSum,
                    });
                    setLoadingTable(false)
                }
            } else {
                console.error('orderItems is not an array', result.orderItems);
            }

            setOrderData(temp_data);
            setServiceCharge(result.serviceFee)
            setName(result.waiterName)
            setRest(result.organizationName)
            setOrganizationId(result.iikoOrganizationId)
            setTableId(result.iikoTableId)
            setOrderId(result.iikoOrderId)
            setWaiterId(result.waiterId)
            setWaiterPhoneNumber(result.waiterPhoneNumber)
            setUrl(data.menuUrl)

        } else {
            if (data.menuUrl) window.location.href = data.menuUrl;
        }

        if ((data.success) || (!data.success && !data.menuUrl)) setSpinnerLoading(false);
        // if (!data.success && !data.menuUrl) setSpinnerLoading(false);


    };
    useEffect(() => {
        const pathname = location.pathname
        if (pathname !== '/') {
            console.log(pathname)
            const restoranId = pathname.split('/')[1]

            const tableId = pathname.split('/')[2]

            fetchData(restoranId, tableId)
        }
    }, [])

    const makePaymentData = async () => {
        setLoading(true)
        const data = await makePayment({
            "iikoOrganizationId": organizationId,
            "iikoTableId": tableId,
            "iikoOrderId": orderId,
            "organizationName": rest,
            "waiterId": waiterId,
            "waiterName": name,
            "waiterPhoneNumber": waiterPhoneNumber,
            "serviceFee": serviceCharge,
            "tipAmount": tipAmount,
            "totalAmount": total,
            "grandTotalAmount": grandTotalAmount,
            "orderItems": orderItems
        });
        setLoading(false)
        if (data && data.success) {
            window.location.href = data.result.paymentUrl;
        } else {
            console.error('Payment failed or no URL returned');
        }

    }


    const onClick = () => {
        // setVisible(true);
        makePaymentData()
    };

    const onMenuClick = (e: any) => {
        window.open(url, '_blank');
    }

    const handleClose = () => {
        setVisible(false);
    };

    const handlePercentage = (percentage: any, index: any) => {
        setTipPercentage(percentage);
        const tipAmount = (total) * (percentage / 100);
        setTipAmount(Number(tipAmount.toFixed(2)))
        if (activeIndex !== index || !className) {
            setClassName(true)
            setGrandTotalAmount(Number((total + tipAmount).toFixed(2)))
        } else {
            setClassName(!className)
            setGrandTotalAmount(total)
        }
        setActiveIndex(index)
        // calculateFinalAmount()
    };
    const tipData = data.map(item => ({
        ...item,
        calculatedValue: ((total) * (parseFloat(item.percentage) / 100)).toFixed(2)
    }));

    const Spinner = () => {
        return (
            <div className="spinner" style={{
                position: 'absolute',
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 100,
                top: 0,
                backgroundColor: '#F4F7FA',
            }}>
                <Flex align="center" gap="middle" >
                    <Spin size="large" />
                </Flex>
            </div>
        )
    }


    return (
        spinnerLoading ? <Spinner /> : <div>
            {(!dataSuccess && !url) ?
                <p style={{
                    color: '#6D7A84',
                    fontSize: 14,
                    textAlign: 'center'
                }}>Aktiv sifariş mövcud deyil</p> :
                <Card
                    title={
                        <div className='title' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: 44, color: '#6D7A84', fontSize: 14 }}>
                            <span style={{ flex: 1, textAlign: 'left' }}>Say</span>
                            <span style={{ flex: 4, textAlign: 'left' }}>Adı</span>
                            <span style={{ flex: 1, textAlign: 'right' }}>Qiymət</span>
                        </div>

                    }
                    style={{ width: 328, margin: 16, maxHeight: 300, overflowY: 'auto' }}
                    bordered={false}
                    className="hidden-scrollbar"
                    loading={loadingTable}
                >

                    <div>
                        {orderData.map((item: any, index: any) => (

                            <Row key={index} justify="space-between" style={{ marginBottom: '8px' }}>
                                <Col span={4}>
                                    <Text>{item.quantity}</Text>
                                </Col>
                                <Col span={14}>
                                    <Text>{item.name}</Text>
                                </Col>
                                <Col span={6} style={{ textAlign: 'right' }}>
                                    <Text style={{ fontWeight: '500' }}>{item.price}</Text>
                                </Col>
                            </Row>
                        ))}
                    </div>
                </Card>}
            <Card className='cardClass' >
                <Row justify="space-between" style={{ marginLeft: -28, marginTop: -28, marginBottom: 3 }}>
                    <Text >Restoran</Text>
                    <Text style={{ fontWeight: '500', marginRight: -28 }}>{rest} </Text>
                </Row>
                <Row justify="space-between" style={{ marginLeft: -28 }}>
                    <Text>Ofisiant</Text>
                    <Text style={{ fontWeight: '500', marginRight: -28 }}>{name} </Text>
                </Row>
                <Row justify="space-between" style={{ marginLeft: -28, marginTop: 5, marginBottom: 3 }}>
                    <Text>Sifariş cəmi</Text>
                    <Text style={{ fontWeight: '500', marginRight: -28 }}>{total.toFixed(2)} ₼</Text>
                </Row>
                <Row justify="space-between" style={{ marginLeft: -28 }}>
                    <Text>Xidmət haqqı (8%)</Text>
                    <Text style={{ fontWeight: '500', marginRight: -28 }}>{serviceCharge.toFixed(2)} ₼</Text>
                </Row>
                <Divider style={{ width: 323, marginLeft: -42, marginTop: 5 }} />
                <div className='percentClass'>
                    <div className='tipsClass'>
                        <img className='icontips' src={icon} alt="tips icon" />
                        <Title level={5} className='tipsText'>Bəxşiş əlavə et</Title>
                    </div>
                    <div>
                        <Row gutter={[16, 12]} style={{ padding: '16px' }}>
                            {tipData.map((item, index) => (
                                <Col key={index} span={6}>
                                    <Button className='tipButtons'
                                        onClick={() => {
                                            handlePercentage(parseFloat(item.percentage), index);

                                        }}
                                        style={{
                                            backgroundColor: activeIndex === index && className ? '#D7E5FC' : '#fff'
                                        }}>
                                        <div style={{ fontSize: '16px', fontWeight: '400', color: ' #2473EA' }}>{item.percentage}%</div>
                                        <div style={{ fontSize: '14px', color: '#22292E' }}>{item.calculatedValue} ₼</div>
                                    </Button>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
                <Divider style={{ width: 323, marginLeft: -42 }} />
                <div className='amountRow'>
                    <Row justify="space-between">
                        <Title className='amountClass' level={4}>Yekun məbləğ</Title>
                        <Title level={4} style={{ backgroundColor: '#F7F7F7', borderRadius: 15, width: 85, textAlign: "center" }}>{grandTotalAmount} ₼</Title>
                    </Row>
                </div>
            </Card>

            <Button
                style={{
                    marginBottom: 0
                }}
                className='button'
                type="primary"
                disabled={orderData.length === 0}
                onClick={onClick}
                loading={loading}
            >
                Ödəniş et
            </Button>

            {url && <Button
                style={{
                    backgroundColor: '#ffffff',
                    border: '1px solid rgb(36, 115, 234)',
                    marginBottom: 0
                }}
                className='button'
                type="link"
                onClick={onMenuClick}
                disabled={loading}
            >
                Menyu
            </Button>}
            {/*<ErrorModal*/}
            {/*    visible={visible}*/}
            {/*    onClose={handleClose}*/}
            {/*/>*/}
        </div>
    );
};

export default PaymentForm;
