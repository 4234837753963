import React, {useEffect} from 'react';
import './App.css';
import './assets/TT Hoves/fonts.css'
import PaymentForm from "./components/PaymentForm/PaymentForm";
import LogoComponent from "./components/LogoComponent/LogoComponent";
import {useLocation} from "react-router-dom";




function App() {





  return (
    <div className="Container">
        <LogoComponent/>
        <PaymentForm/>



    </div>

  );
}

export default App;
